@import "../scss/color.module.scss";
$imageUrl: "../../public/Images/";

//set path of the image
@function toAbsoluteUrl($pathname) {
  $image: url($imageUrl + $pathname);
  @return $image;
}

//set image
@mixin imageUrl($pathName, $set-image: true, $color: black) {
  @if $set-image {
    -webkit-mask: toAbsoluteUrl($pathName);
    background-color: $color;
    -webkit-mask-size: 20px;
  } @else {
    -webkit-mask: none;
  }
  -webkit-mask-repeat: no-repeat;
}

$img-video: "video.png";
$img-camera: "camera.png";
$img-documents: "documents.png";
$img-audio: "audio.png";
$img-share: "share.png";
$img-more: "menu.png";

$imagesArray: (
  "video": "video.png",
  "camera": "camera.png",
  "documents": "documents.png",
  "audio": "audio.png",
);
$img-share: "share.png";
$img-more: "menu.png";

$plus-img: "plus.png";

$icons-svg: (
  "Work": $picture,
  "Personal": $document,
  "School": $video,
  "Archive": $audio,
);

$color-cards: (
  "Pictures": $picture,
  "Documents": $document,
  "Videos": $video,
  "Audio": $audio,
);

$colors: (
  1: #bde5eb,
  2: #d7d7ff,
  3: #f7dae7,
);

$xxl: 1600px;
$xl: 1439px;
$lg: 1199px;
$md: 991px;
$sm: 767px;
$xs: 575px;
$font-stack: Georgia, serif;
