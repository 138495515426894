@import "../../scss/constant.module.scss";
@import "../../scss/mixin.scss";
@import "../../scss/color.module.scss";

@mixin commonCardStyle() {
  height: 140px;
  width: 170px;
  // margin-right: 15px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

@mixin colorImage($color: white) {
  @each $img, $value in $imagesArray {
    //image
    .img-#{$img} {
      @include imageUrl($value, $set-image: true, $color: $color);
      background-size: contain;
      width: 20px;
      height: 20px;
    }
  }
}

.wrapper {
  display: flex;
  .leftElements {
    width: 100%;
  }
  .rightElements {
    width: 40%;
  }
}
