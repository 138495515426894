.circleAvatar {
  display: flex;
  justify-content: center;
  img {
    border: 3px solid rgb(255, 255, 255);
    height: 5.5rem;
    width: 5.5rem;
    border-radius: 100%;
    background-color: white;
  }
}
