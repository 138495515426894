@import "../../pages/Dashboard/dashboard.module.scss";
@import "../../scss/constant.module.scss";

.wrapper {
  display: block;
  .newfiles {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    .favFiles {
      display: grid;
      gap: 2rem;
      //responsive grid layout
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      .favoriteCards {
        display: flex;
        flex-direction: column;
        min-height: 250px;
        // min-width: 250px;
        padding: 10px;

        // margin: 12px;
        .bg-image {
          display: flex;
          background-size: cover;
          width: 100%;
          border-radius: 8px;
          height: 100%;
          background-position: center center;
          background-image: url("https://images.unsplash.com/photo-1516370873344-fb7c61054fa9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMDUzMDJ8MHwxfHNlYXJjaHwxMTB8fGJlYWNofGVufDF8fHx8MTY1NjkzNDUyNg&ixlib=rb-1.2.1&q=80&w=1080");
          background-repeat: no-repeat;
        }
        span {
          font-size: medium;
          scale: 1;
        }
      }
    }
  }
}
